<template>
    <div class="des-wrap">
        <span class="lab">每日返{{info.fanli_day_rate}}</span>
        <div class="head">
            <div class="tx-box">
                <img class="tx" :src="rImg(user.head_portrait)" :onerror="txImg" alt="">
                <div class="username">{{user.name}}</div>
            </div>
            <!-- <div class="shopInfo">
                <img class="shop-img" src="../../assets/test/05.jpeg" alt="">
                <div class="info">
                    <div class="shop-name ellipsis-2"><span class="lab">返现80%</span>商品名称商品名称商品名称商品名称商品名称商品名称商品名称</div>
                    <div class="price">￥92.4</div>
                </div>
            </div> -->
            <div class="shopInfo">
                <div class="price"><span class="price-txt">待返积分</span>{{(info.fanli_count-info.fanlied_count).toFixed(2)}}</div>
                <div class="des">当前返利已加速{{info.fanli_day_rate_add}}</div>
            </div>
            <div class="link" v-if="showLink">分享：{{speed_url}}</div>
        </div>
        <div class="process">
            <div class="pro-box">
                <div class="pro" :style="'width:'+info.fanlied_rate">
                    <div class="proInfo-box" :class="{'proInfo-left':proLeft}">
                        <div class="proInfo">已返利<br>{{info.fanlied_rate}}</div>
                        <!-- <div class="tri"></div> -->
                    </div>
                </div>
            </div>
            <div class="flex-bt price-box">
                <div class="des"><span class="des-txt">已返积分</span>{{info.fanlied_count}}</div>
                <div class="des"><span class="des-txt">总返积分</span>{{info.fanli_count}}</div>
            </div>
            <button v-if="info.user_id == myid" id="copyBtn" :data-clipboard-text="speed_url" data-clipboard-action="copy" class="kan-btn">请好友加速</button>
            <button v-else-if="!info.is_accelerate" class="kan-btn" @click.stop="addSpeed()">帮TA加速返利</button>
        </div>
        <div class="friend-box">
            <div class="title-box">
                <div class="line"></div>
                <span class="title">加速帮</span>
            </div>
            <ul class="ul" v-if="list.length">
                <li class="li" v-for="(m, mi) in list">
                    <div class="user" v-if="m.user">
                        <img class="tx" :src="rImg(m.user.head_portrait)" :onerror="txImg" alt="">
                        <div class="name-box">
                            <div class="name">{{m.user.name}}</div>
                            <p class="des">{{m.time}}</p>
                        </div>
                    </div>
                    <span class="val">已加速{{m.fanli_rate}}</span>
                </li>
                <p class="next-page" @click.stop="getList()" v-if="total!=-1">查看更多</p>
            </ul>
            <div class="null" v-else>还没有好友为你加速，快去邀请朋友吧</div>
        </div>
    </div>
</template>
<script>
    import Clipboard from 'clipboard';
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                id: '',
                myid: 0, // 我的id
                user: {}, // 冻结详情所属用户资料
                list: [], // 加速列表
                info: {}, // 冻结详情
                total: -1,
                proLeft: 0,
                showLink: 0,
                speed_url: '',
                clipboard: ''
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.id = this.$route.query.id;
            this.speed_url = 'http://' + window.location.hostname + '/#/frozendes?id=' + this.id;
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData();
            this.getList(1);
            this.clipboard = new Clipboard('#copyBtn');
            let _this = this;
            this.clipboard.on('success', function(e) {
                _this.$y_msg('复制成功，快去分享让朋友帮你加速吧');
            });
            this.clipboard.on('error', function(e) {
                _this.$y_msg('复制失败，请手动复制')
                _this.showLink = 1;
            });
        },
        beforeDestroy() {
            this.clipboard.destroy();
        },
        methods: {
            ...mapMutations(["s_setLoginModal"]),
            async addSpeed() {
                let myid = this.$y_getKey('userid')
                if (!myid) {
                    // 登录
                    this.s_setLoginModal(1);
                    return;
                }
                this.myid = myid;
                let ret = await this.$y_ajax("api/mall.rebate/accelerate", {
                    id: this.id
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    this.info.is_accelerate = true;
                    await this.getList(1);
                }
            },
            // ...mapMutations([])
            // 冻结详情
            async getData() {
                let myid = this.$y_getKey('userid')
                if (myid) {
                    this.myid = myid;
                }

                let ret = await this.$y_ajax("api/mall.rebate/info", {
                    id: this.id
                });
                if (ret) {
                    if (ret.code == 200) {
                        this.info = ret.data;
                        let r = this.info.fanlied_rate.replace('%', '');
                        if (r < 50) {
                            this.proLeft = 1;
                        }
                        if (!this.user || !this.user.id) {
                            await this.getUser(this.info.user_id);
                        }
                    } else if (ret.msg) {
                        this.$y_msg(ret.msg)
                    }
                }
            },
            // 加速列表
            async getList(init) {
                let arr = this.list;
                if (init) {
                    arr = [];
                }
                let {
                    list,
                    total
                } = await this.$y_list("api/mall.rebate/accelerate_records", arr, {
                    id: this.id
                });
                this.list = list;
                this.total = total;
            },
            async getUser(uid) {
                let {
                    result
                } = await this.$y_ajax("api/user/info", {
                    user_id: uid,
                    to_user: uid,
                    longitude: 1,
                    latitude: 1,
                });
                this.user = result;
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            s_getUser() {
                this.getData();
            },
        }
    };
</script>

<style lang="less" scoped>
    .font-red {
        color: red;
    }

    .des-wrap {
        background: #e74444;
        padding: 50px 20px 20px;
        min-height: 100vh;
        position: relative;


        .lab {
            font-size: 12px;
            color: orangered;
            background: #fff;
            border-radius: 0 15px 15px 0;
            padding: 5px 10px;
            position: absolute;
            top: 20px;
            left: 0;
            z-index: 1;
        }

        .head {
            background: #fff;
            border-radius: 50px 50px 0 0;
            position: relative;
            border: 2px solid red;
            box-shadow: 1px 1px 2px #00000078;

            .tx-box {
                text-align: center;
                margin-top: -35px;
            }

            .tx {
                width: 70px;
                height: 70px;
                border: 1px solid #ddd;
                border-radius: 50%;
                background: #fff;
            }

            .shop-title {
                font-size: 16px;
            }

            .link {
                padding: 20px 0;
                text-align: center;
                color: #007ACC;
            }


            .shopInfo {
                padding: 15px;
                text-align: center;
                position: relative;

                .price {
                    margin-top: 10px;
                    font-size: 25px;
                    color: orangered;

                    .price-txt {
                        font-size: 12px;
                    }
                }

                .des {
                    margin-top: 20px;
                    color: orange;
                }
            }
        }

        .process {
            margin: 50px 0 20px;
            position: relative;


            .proInfo-box {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -40px;
                right: 0;

                &.proInfo-left {
                    left: 50px;
                }

                .proInfo {
                    background: #fff;
                    color: red;
                    font-size: 12px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    min-width: 100px;
                    text-align: center;
                    border-radius: 3px;
                    margin-bottom: 5px;
                }

                .tri {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 10px solid #fff;
                }
            }

            .pro-box {
                background: #960909;
                height: 17px;
                border-radius: 20px;
                box-shadow: 1px 1px 1px #fff;
                padding-top: 2px;
                box-sizing: border-box;
                padding-left: 1px;

                .pro {
                    background: linear-gradient(to left, red, yellow);
                    height: 15px;
                    border-radius: 20px;
                    min-width: 10px;
                    position: relative;
                }
            }

        }

        .price-box {
            color: #fff;
            font-size: 14px;
            margin-top: 3px;

            .des-txt {
                font-size: 12px;
                margin-right: 3px;
            }
        }

        .kan-btn {
            margin: 20px 0;
            color: red;
            background: -webkit-linear-gradient(yellow, yellow);
            background: linear-gradient(yellow, #ca8007);
            width: 100%;
            border-radius: 20px;
            line-height: 40px;
            text-align: center;
            box-shadow: 0px 2px 2px #8a3211;
            animation: trans 0.8s infinite;
            -webkit-animation: trans 0.8s infinite;
            border: 0;
            outline: 0;
        }

        .friend-box {
            background: pink;
            position: relative;
            padding: 30px 10px;

            .title-box {
                text-align: center;
                position: relative;
                margin-bottom: 20px;

                .title {
                    display: inline-block;
                    background: pink;
                    padding: 0 15px;
                    position: relative;
                }

                .line {
                    height: 3px;
                    width: 100%;
                    background: red;
                    border-radius: 50%;
                    transform: scale(1, 0.5);
                    margin-bottom: -10px;
                }
            }

            .li {
                border-bottom: 1px dashed red;
                padding: 10px 0;
                margin: 0 10px;
                display: flex;
                align-items: center;

                .user {
                    margin-right: 10px;
                    flex: 1;
                    display: inline-flex;
                    align-items: center;
                }

                .tx {
                    margin-right: 7px;
                    width: 40px;
                    height: 40px;
                }

                .des {
                    color: rgba(255, 0, 0, 0.3);
                    font-size: 12px;
                    margin-top: 5px;
                }

                .val {
                    font-size: 12px;
                    color: red;
                }
            }
        }
    }

    .null {
        text-align: center;
        font-size: 12px;
        color: red;
        padding: 50px 0;
    }

    .next-page {
        padding: 20px 0;
        text-align: center;
        color: rgba(255, 0, 0, 0.7);
        cursor: pointer;
    }

    @keyframes trans {
        0% {
            transform: scale(0.9, 0.9);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    @-webkit-keyframes trans {
        0% {
            transform: scale(0.9, 0.9);
        }

        100% {
            transform: scale(1, 1);
        }
    }
</style>